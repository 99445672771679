import React from 'react';
import ProLink from '../../ProLink/ProLink';
import { miniGazelle } from '../../../../helpers'
import classNames from 'classnames';

class Full extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { results = [], selectedResultKey } = this.props;
    return (
      <ul className="list-unstyled list-unstyled-full">
        {results.length > 0 &&
          results.map((result, i) => {
            return (
              <li
                key={`item${i}`}
                className={classNames({
                  active: selectedResultKey === i
                })}
              >
                <h5>
                  {result.name}{' '}
                  {result.retired && (
                    <span className="label label-warning smaller-sup discontinued-label discontinued-typeahead">
                      {miniGazelle.label('isDiscontinued', true)}
                    </span>
                  )}
                </h5>
                {result.description && <p>{result.description}</p>}
                {result.thumbnail && (
                  <img
                    className="thumbnail"
                    src={result.thumbnail}
                    alt={result.name}
                  />
                )}

                <ProLink to={result.link} className="block-link">
                  {result.name}
                </ProLink>
              </li>
            );
          })}
      </ul>
    );
  }
}

export default Full;
