import React from 'react';
import request from '../../../http/request';
import ProLink from '../../shared/ProLink/ProLink';
import { miniGazelle, currentLocale, getUrl, isClient, isExternal } from '../../../helpers';

import './LoginRegister.scss';

class LoginRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            url: `${getUrl()}/${currentLocale.get()}/api/user/login`,
            logoutUrl: `${getUrl()}/${currentLocale.get()}/api/user/logout`,
            loading: false,
            loginEmail: null,
            loginPassword: null,
            registerEmail: null,
            error: false,
            registerSuccess: false,
        };

        this.logout = this.logout.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.isLoading = this.isLoading.bind(this);
        this.redirectUser = this.redirectUser.bind(this);
    }

    isLoading() {
        this.setState({
            loading: true,
        });
    }

    handleLogout(e) {
        e.preventDefault();
        if (isClient()) {
            this.isLoading();
            this.logout();
        }
    }

    logout() {
        const url = this.state.logoutUrl;

        let fetch = request
            .post(url)
            .send({ logout: true })
            .then(res => {
                this.redirectUser(res.body.target);
            })

            .catch(err => {
                this.setState({
                    error: true,
                    loading: false,
                });
                console.warn(err);
            });

        return fetch;
    }

    redirectUser(target) {
        let targetUrl;
        if (isExternal(target)) {
            targetUrl = target;
        } else {
            targetUrl = `${getUrl()}${target}`;
        }
        window.location.href = targetUrl;
    }

    render() {
        const { loginRegisterClassNames } = this.props;

        const user = this.props.user
            ? this.props.user
            : {
                  data: {},
                  signedIn: false,
              };

        return (
            <div className={'login-register ' + loginRegisterClassNames}>
                <div>
                    <div className="col-md-12 column">
                        <h4>
                            {miniGazelle.label('welcomeMessage')} {user.data.name}
                        </h4>

                        <ProLink
                            className="btn btn-standard"
                            to={user.data.updateDetails ? user.data.updateDetails : '/user/details'}
                        >
                            {!this.state.loading && miniGazelle.label('account', true)}
                            {this.state.loading && <span className="form-loading" />}
                        </ProLink>

                        <button
                            type="submit"
                            className="btn btn-white"
                            disabled={this.state.loading}
                            onClick={this.handleLogout}
                            data-testid="logout"
                        >
                            {!this.state.loading && miniGazelle.label('logout', true)}
                            {this.state.loading && <span className="form-loading" />}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginRegister;
