import React from 'react';

import TypeAhead from '../TypeAhead/TypeAhead';

import { miniGazelle } from '../../../helpers';

class HeaderSearch extends React.Component {
    render() {
        const { searchAheadClassNames, locale, searchInputID, onKeyDown } = this.props;

        return (
            <form
                className={searchAheadClassNames}
                method="GET"
                action={`/${locale}/search`}
                data-testid="header-search-form"
                onKeyDown={onKeyDown}
                tabIndex={0}
                role="button"
            >
                <TypeAhead
                    placeholder={miniGazelle.label('searchPlaceholder')}
                    showAllResultsLink={`/${locale}/search?q=`}
                    showAllResults={miniGazelle.label('showAllResults')}
                    locale={locale}
                    type="g"
                    willShowAllLink={true}
                    name="q"
                    id={searchInputID}
                    autoFocus={true}
                    searchOnClick={true}
                    useFull={true}
                />

                <input type="hidden" name="type" value="g" />
            </form>
        );
    }
}

export default HeaderSearch;
